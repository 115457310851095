<div class="inner-page-banner" *ngIf="!isLoading">
    <div class="container-fluid">
        <!-- <div class="inner-page-banner-bg"> -->
        <h1 class="h1" style="color: black; text-align: center;">{{sections?.section[0].title}}</h1>
        <!-- </div> -->
    </div>
</div>

<div class="content-page" *ngIf="!isLoading">
    <div class="container">
        <p [innerHtml]="sections?.section[0].description"></p>
    </div>
</div>
<app-footer></app-footer>