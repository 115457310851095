<div class="inner-page-banner">
    <div class="container-fluid">
        <div class="inner-page-banner-bg" [ngStyle]="{ 'background-image': 'url(' + bannerImageUrl + ')' }">
            <h1 class="h1" *ngIf="sections?.banner?.length > 0">{{sections?.banner[0].title}}</h1>
        </div>
    </div>
</div>

<div class="contact-sec contact-page-sec">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-xl-6 col-lg-12">
                    <app-contact-form (dataToParent)="handleDataFromChild($event)"></app-contact-form>
                </div>
                <div class="col-xl-6 col-lg-12 contact-col">
                    <div class="contact-img">
                        <img [src]="imageUrl" alt="contact-img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
