import { Component, AfterViewInit, Inject, PLATFORM_ID, SimpleChanges, OnChanges, ChangeDetectorRef, Input, ViewEncapsulation } from '@angular/core';
declare var $: any;
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { SectionData } from '../models/section-data.model';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { LoadingService } from '../services/loading.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HomeSectionService } from '../services/home-section.service';
import { ToastService } from '../services/toast.service';
import { FooterComponent } from '../footer/footer.component';
declare const initializeSlickSlider: () => void;

@Component({
  selector: 'app-home-section',
  standalone: true,
  host: { ngSkipHydration: 'true' },
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContactFormComponent,
    FooterComponent
  ],
  templateUrl: './home-section.component.html',
  styleUrl: './home-section.component.css',
  encapsulation: ViewEncapsulation.None
})
export class HomeSectionComponent implements AfterViewInit, OnChanges {

  sections: any = {};
  hotelsData: any = [];
  travelPackages: any = [];
  backgroundImageUrl = 'assets/img/main-banner-img.webp';
  isPersonalizeModalOpen: boolean = false;
  PersonalizeModalData: any = {};
  personalizeForm: FormGroup;
  apiErrors: { [key: string]: string } = {}; // Store API errors here
  travelPackagesSet1 = [];
  travelPackagesSet2 = [];
  travelPackagesSets: any = [];


  constructor(@Inject(PLATFORM_ID) private platformId: any,
    public apiService: ApiService,
    private homeSectionService: HomeSectionService,
    public loadingService: LoadingService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private cdRef: ChangeDetectorRef
  ) {
    this.personalizeForm = this.fb.group({
      full_name: ['', Validators.required],
      phone_no: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      package_id: [''],
      package_name: ['']
    });
  }

  async ngOnInit() {
    this.loadingService.show();

    try {
      await Promise.all([
        this.loadHomePageData(),
        this.loadTopHotelsData(),
        this.loadTravelPackages()
      ]);
    } catch (error) {
      // console.error('Error loading data:', error);
    } finally {
      this.loadingService.hide(); // Hide loader only after all data has loaded
    }
  }

  async loadHomePageData() {
    this.homeSectionService.getHomeSectionData().subscribe(data => {
      this.sections = data;
      if (this.sections?.banner[0]?.image) {
        this.backgroundImageUrl = this.sections.banner[0].image;
      }
    });

  }

  async loadTopHotelsData() {
    await this.apiService.getTopHotelsData().then(async (response: any) => {
      this.hotelsData = response.data;
    });
  }

  async loadTravelPackages() {
    await this.apiService.getTravelPackages().then(async (response: any) => {
      this.travelPackages = response.data;

      const chunkSize = 2;
      for (let i = 0; i < this.travelPackages.length; i += chunkSize) {
        const set = this.travelPackages.slice(i, i + chunkSize);
        this.travelPackagesSets.push(set);
      }
      this.initializeSlickSlider();
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    // Reinitialize slick if data changes dynamically
    if (changes['travelPackages']) {
      this.initializeSlickSlider();
    }
  }

  ngAfterViewInit(): void {
    // Initialize slick slider after the view is fully initialized
    this.initializeSlickSlider();
  }

  initializeSlickSlider() {
    if (this.travelPackages.length && isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        $(document).ready(() => {
          initializeSlickSlider();
        });
      });
    }
  }


  personalizeModalOpen(data: any): void {
    this.PersonalizeModalData = data;  // Set dynamic content
    if (this.PersonalizeModalData) {
      this.personalizeForm.patchValue({
        package_id: this.PersonalizeModalData.id,
        package_name: this.PersonalizeModalData.name,
      });
    }
    this.isPersonalizeModalOpen = true;
  }

  personalizeModalClose(): void {
    this.isPersonalizeModalOpen = false;

  }


  async submitPersonalizeForm() {
    this.loadingService.show();

    const formData = this.personalizeForm.value;
    try {
      const response = await this.apiService.submitPersonalizeForm(formData);
      if (response.body.error) {
        const errors = response.body.data;
        // Set form control errors
        this.apiService.setApiErrors(this.personalizeForm, errors);
        this.cdRef.detectChanges();
        this.loadingService.hide();
      } else {
        this.toastService.showToast((response.body.message) ? response.body.message : 'Package personalize request submitted successfully!');
        this.apiErrors = {};
        this.personalizeForm.reset();
        this.loadingService.hide();
        setTimeout(() => {
          this.personalizeModalClose();
        }, 2000);
      }
    } catch (error) {
      this.loadingService.hide();
      this.personalizeForm.reset();
      this.personalizeModalClose();
      this.toastService.showToast('Will reach you shortly');
    }
  }

}

