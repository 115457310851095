import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent

  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.css',
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent {
  sections: any = {};
  historyImageUrl: string = 'assets/img/about-img.webp';
  whyUsImageURL: string = 'assets/img/about-img.webp';
  bannerImageUrl: string = 'assets/img/inner-page-banner.webp';

  constructor(public apiService: ApiService) { }

  async ngOnInit() {
    this.loadAboutUsPageData();
  }


  async loadAboutUsPageData() {
    await this.apiService.getAboutUsPage().then(async (response: any) => {
      this.sections = response.data;
      if (this.sections?.top[0]?.image) {
        this.historyImageUrl = this.sections?.top[0]?.image
      }
      if (this.sections?.bottom[0].image) {
        this.whyUsImageURL = this?.sections?.bottom[0]?.image
      }
      if (this.sections?.banner[0]?.image) {
        this.bannerImageUrl = this.sections?.banner[0]?.image
      }
    });
  }

}
