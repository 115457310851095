import { Component, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { ApiService } from '../services/api.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-disclaimer',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent
  ],
  templateUrl: './disclaimer.component.html',
  styleUrl: './disclaimer.component.css',
  encapsulation: ViewEncapsulation.None
})
export class DisclaimerComponent {
  sections: any = {};
  imageSrc: string = 'assets/img/about-img.webp';
  imageSrc1: string = 'assets/img/about-img.webp';
  isLoading: boolean = true;

  constructor(public apiService: ApiService) { }

  async ngOnInit() {
    this.loadPageData();
  }


  async loadPageData() {
    await this.apiService.getDisclaimerPage().then(async (response: any) => {
      this.sections = response.data;
      this.isLoading = false;
    });
  }

}
