import { ContactFormComponent } from './../contact-form/contact-form.component';
import { Component, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    ContactFormComponent,
    CommonModule
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.css',
  encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent {

  bannerImageUrl: string = 'assets/img/inner-page-banner.webp';
  imageUrl: string = 'assets/img/contact-img.webp';
  sections: any = {};

  handleDataFromChild(data: any) {
    this.sections = data
    if (this.sections?.banner[0].image) {
      this.bannerImageUrl = this.sections?.banner[0].image
    }
    if (this.sections?.top[0].image) {
      this.imageUrl = this.sections?.top[0].image
    }
  }

}
