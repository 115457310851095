import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import { LoadingService } from '../services/loading.service';
import { FooterComponent } from '../footer/footer.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [
    FooterComponent,
    CommonModule
  ],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.css'
})
export class TermsComponent {
  sections: any = {};
  isLoading = true;
  constructor(public apiService: ApiService, public loadingService: LoadingService) { }

  async ngOnInit() {
    this.loadPageData();
  }


  async loadPageData() {
    this.loadingService.show();

    await this.apiService.getTermsPage().then(async (response: any) => {
      this.sections = response?.data;
      this.loadingService.hide();
      this.isLoading = false;

    }, error => this.loadingService.hide());
  }
}
