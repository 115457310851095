import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TopHotelsComponent } from './top-hotels/top-hotels.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { TopHotelsDetailComponent } from './top-hotels-detail/top-hotels-detail.component';
import { NgModule } from '@angular/core';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiesComponent } from './cookies/cookies.component';
import { TermsComponent } from './terms/terms.component';
import { TopHotelsSectionComponent } from './top-hotels-section/top-hotels-section.component';
import { TopHotelsDetailSectionComponent } from './top-hotels-detail-section/top-hotels-detail-section.component';
import { NotfoundComponent } from './notfound/notfound.component';

export function HotelMatcher(url: UrlSegment[]) {
  return (url.length === 1 && url[0].path.startsWith('hotel')) ? ({ consumed: url }) : null;
}

export const routes: Routes = [

  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', redirectTo: 'about-us/', pathMatch: 'full' },
  { path: 'about-us/', component: AboutUsComponent },
  { path: 'top-hotels', redirectTo: 'top-hotels/', pathMatch: 'full' },
  { path: 'top-hotels/', component: TopHotelsSectionComponent },
  { path: 'contact-us', redirectTo: 'contact-us/', pathMatch: 'full' },
  { path: 'contact-us/', component: ContactUsComponent },
  { path: 'disclaimer', redirectTo: 'disclaimer/', pathMatch: 'full' },
  { path: 'disclaimer/', component: DisclaimerComponent },
  { path: 'privacy-policy', redirectTo: 'privacy-policy/', pathMatch: 'full' },
  { path: 'privacy-policy/', component: PrivacyPolicyComponent },
  { path: 'cookies', redirectTo: 'cookies/', pathMatch: 'full' },
  { path: 'cookies/', component: CookiesComponent },
  { path: 'terms', redirectTo: 'terms/', pathMatch: 'full' },
  { path: 'terms/', component: TermsComponent },
  // { matcher: HotelMatcher, component: TopHotelsDetailSectionComponent, pathMatch: 'full' },
  { path: 'top-hotels/:hotel-details', redirectTo: 'top-hotels/:hotel-details/', pathMatch: 'full' },
  { path: 'top-hotels/:hotel-details/', component: TopHotelsDetailComponent },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
