<header class="header" *ngIf="!isLoading">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="/">
                <img [src]="logoSrc" alt="logo">
            </a>


            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
            </button>


            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item" [class.active]="isActive('/')">
                        <!-- <a class="nav-link" href="/" (click)="navigateTo($event, '/')">Home</a> -->
                        <a class="nav-link" href="/" (click)="navigateTo($event, '/')">Home</a>
                    </li>
                    <li class="nav-item" [class.active]="isActive('/about-us/')">
                        <a class="nav-link" href="/about-us/" (click)="navigateTo($event, '/about-us/')">About Us</a>
                    </li>
                    <li class="nav-item" [class.active]="isActive('/top-hotels/')">
                        <a class="nav-link" href="/top-hotels/" (click)="navigateTo($event, '/top-hotels/')">Top
                            Hotels</a>
                    </li>
                    <li class="nav-item" [class.active]="isActive('/contact-us/')">
                        <a class="nav-link" href="/contact-us/" (click)="navigateTo($event, '/contact-us/')">Contact
                            Us</a>
                    </li>
                    <li class="nav-item" [class.active]="isActive('/disclaimer/')">
                        <a class="nav-link" href="/disclaimer/"
                            (click)="navigateTo($event, '/disclaimer/')">Disclaimer</a>
                    </li>
                </ul>

            </div>
        </nav>
    </div>
</header>