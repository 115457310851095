import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  showToast(message: string) {
    // Create the toast element
    const toastElement = document.createElement('div');
    toastElement.classList.add('custom-toast');
    toastElement.innerText = message;
  
    // Find the container and append the toast
    const toastContainer = document.getElementById('toast-container');
    toastContainer?.appendChild(toastElement);
  
    // Remove the toast after 3 seconds
    setTimeout(() => {
      toastElement.classList.add('fade-out');
      setTimeout(() => {
        toastElement.remove();
      }, 1000); // Allow time for fade-out animation before removal
    }, 3000); // Toast will stay for 3 seconds
  }
}
