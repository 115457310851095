import { ChangeDetectorRef, Component, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { filter, map, tap } from 'rxjs';
import { MetaService } from './services/meta.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { LoadingService } from './services/loading.service';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoggerService } from './logger.service';
import $ from 'jquery';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, CommonModule, FooterComponent, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'Compare Hotels';

  constructor(
    private router: Router,
    private metaService: MetaService,
    public loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private _loggerService: LoggerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this._loggerService.disableLog();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects),
      tap((url: string) => {
        this.metaService.updateMeta(url);
        if (isPlatformBrowser(this.platformId)) {
          this.applyNavbarActions();
        }

      })
    ).subscribe();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  applyNavbarActions() {
    $('body').removeClass('open-menu');
    $('.navbar-collapse').removeClass('collapse');
    $('.navbar-toggler').addClass('collapsed');
  }
  
}
