import { ChangeDetectorRef, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastService } from '../services/toast.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.css',
  encapsulation: ViewEncapsulation.None
})
export class ContactFormComponent {

  @Output() dataToParent = new EventEmitter<string>();

  public sections: any = {};
  contactForm: FormGroup;
  apiErrors: { [key: string]: string } = {}; // Store API errors here
  isLoading = true;

  constructor(public apiService: ApiService, private fb: FormBuilder, private cdRef: ChangeDetectorRef, private toastService: ToastService, public loadingService: LoadingService) {
    this.contactForm = this.fb.group({
      full_name: ['', Validators.required],
      phone_no: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });

  }

  async ngOnInit() {
    this.loadContactFormData();
  }

  async loadContactFormData() {
    await this.apiService.getContactUsPage().then(async (response: any) => {
      this.sections = response.data;
      this.dataToParent.emit(this.sections);
      this.isLoading = false
    });
  }

  async submitForm() {
    this.loadingService.show();

    // if (this.contactForm.valid) {
    const formData = this.contactForm.value;

    try {
      const response = await this.apiService.submitContactUsForm(formData);
      if (response.body.error) {
        const errors = response?.body?.data; // Adjust this based on your backend's error structure
        // Set form control errors
        if (errors) {
          this.apiService.setApiErrors(this.contactForm, errors);
        }
        this.cdRef.detectChanges();
        this.loadingService.hide();
      } else {
        this.toastService.showToast((response.body.message) ? response.body.message : 'Thank you for submitted successfully!');
        this.apiErrors = {}; // Clear any previous errors
        this.contactForm.reset(); // Reset the form on success
        this.loadingService.hide();
      }
    } catch (error) {
      this.loadingService.hide();
      this.contactForm.reset(); // Reset the form on success
      this.toastService.showToast('Will reach you shortly');
    }
  }

}

