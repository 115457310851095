<div class="inner-page-banner">
    <div class="container-fluid">
        <div class="inner-page-banner-bg" [ngStyle]="{ 'background-image': 'url(' + bannerImageUrl + ')' }">
            <h1 class="h1" *ngIf="sections?.banner?.length > 0">{{sections?.banner[0].title}}</h1>
        </div>
    </div>
</div>

<div class="about-sec">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="about-img">
                    <img [src]="historyImageUrl" alt="about-img">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-content" *ngIf="sections?.top?.length > 0">
                    <h2 class="h2"><span class="primary-text">{{ sections?.top[0]?.title }}</span>
                        {{ sections?.top[0]?.short_description }}</h2>
                    <p>{{ sections?.top[0]?.description }}</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="why-us-sec">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-us-content">
                    <span class="h5 primary-text" *ngIf="sections?.bottom?.length > 0">{{ sections?.bottom[0]?.title
                        }}</span>
                    <h2 class="h2" *ngIf="sections?.bottom?.length > 0">{{ sections?.bottom[0]?.short_description}}</h2>
                    <div>
                        <div class="why-us-item" *ngFor="let about of sections?.services; let i=index">
                            <span class="pl-tag why-us-tag">{{about.short_description}}</span>
                            <p>{{about.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-us-img">
                    <img [src]="whyUsImageURL" alt="why-us-img">
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>

