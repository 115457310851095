import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeSectionService {

  constructor() { }

  private homeSectionSubject = new BehaviorSubject<any>(null);

  // Method to update the data
  updateHomeSectionData(data: any) {
    this.homeSectionSubject.next(data);
  }

  // Method to get the current data as an Observable
  getHomeSectionData() {
    return this.homeSectionSubject.asObservable();
  }

}
