import { Component, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { CommonModule } from '@angular/common';
import { LoadingService } from '../services/loading.service';
declare function getFullStars(rating: number): boolean[];
declare function hasHalfStar(rating: number): boolean;
declare function getEmptyStars(rating: number): number[];

@Component({
  selector: 'app-top-hotels-detail',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent
  ],
  templateUrl: './top-hotels-detail.component.html',
  styleUrl: './top-hotels-detail.component.css',
  encapsulation: ViewEncapsulation.None
})
export class TopHotelsDetailComponent {
  hotelId: string | null = null;
  hotelData: any = {};
  isImageModalOpen: boolean = false;
  currentImage: string | null = null;
  isLoading: boolean | null = null;

  constructor(private route: ActivatedRoute, public apiService: ApiService, private router: Router, public loadingService: LoadingService) { }

  async ngOnInit() {
    this.loadingService.show();
    const url = this.route.snapshot.url.join('-');

    const parts = url.split('-');
    this.hotelId = parts[parts.length - 2];

    await this.getHotelDetails(this.hotelId);

    // try {
    //   // await Promise.all([
    //   await this.getHotelDetails(this.hotelId);
    //   // ]);
    //   this.loadingService.hide();
    //   this.isLoading = false
    // } catch (error) {
    //   console.error('Error loading data:', error);
    // }
  }



  async getHotelDetails(hotelId: any) {
    if (hotelId) {
      await this.apiService.getHotelDetails(hotelId).then(async (response: any) => {
        this.hotelData = response?.data;
        this.isLoading = false;  // Assuming you want to stop the loading indicator after the API call
        setTimeout(() => {
          this.loadingService.hide();
        }, 100);

        if (!this.hotelData?.id) {
          // Display details for a brief time before redirecting to 404 page
          setTimeout(async () => {
            await this.router.navigate(['/404']);  // Redirect to 404 page
            this.loadingService.hide();
            this.isLoading = null;
          }, 0); // Show details for 3 seconds before redirecting
        }
      }).catch(async error => {
        // Handle API errors if necessary
        this.loadingService.hide();
        this.isLoading = null;
        await this.router.navigate(['/404']);  // Redirect to 404 in case of error
      });
    }
  }


  imageModalOpen(imageUrl: string): void {
    this.currentImage = imageUrl;  // Set dynamic content
    this.isImageModalOpen = true;
  }

  imageModalClose(): void {
    this.isImageModalOpen = false;
    this.currentImage = null;
  }



  getFullStars(rating: number): number[] {
    // Returns an array representing the full stars
    return Array(Math.floor(rating)).fill(0);
  }

  hasHalfStar(rating: number): boolean {
    // Checks if there is a half star
    return rating % 1 >= 0.5;
  }

  getEmptyStars(rating: number): number[] {
    // Returns an array representing the empty stars
    const fullStars = Math.floor(rating);
    const hasHalf = rating % 1 >= 0.5 ? 1 : 0;
    return Array(5 - fullStars - hasHalf).fill(0);
  }
}
