import { Component, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { ApiService } from '../services/api.service';
import { CommonModule } from '@angular/common';
import { LoadingService } from '../services/loading.service';
declare function getFullStars(rating: number): boolean[];
declare function hasHalfStar(rating: number): boolean;
declare function getEmptyStars(rating: number): number[];

@Component({
  selector: 'app-top-hotels',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent
  ],
  templateUrl: './top-hotels.component.html',
  styleUrl: './top-hotels.component.css',
  encapsulation: ViewEncapsulation.None
})
export class TopHotelsComponent {

  url = '';
  hotelsData: any = [];
  hotelsTotalPages: any = "";
  sections: any = {};
  bannerImageUrl: string = 'assets/img/inner-page-banner.webp';
  isLoading = true;
  currentPage: number = 1;

  constructor(public apiService: ApiService, public loadingService: LoadingService
  ) { }


  hotelUrl(slug: any, id: any) {
    this.url = 'hotel-' + slug + '-' + id;
    return this.url;
  }

  async ngOnInit() {
    this.loadingService.show();

    try {
      await Promise.all([
        this.loadTopHotelsList(),
        this.loadTopHotelsPageData()
      ]);
    } catch (error) {
      // console.error('Error loading data:', error);
    } finally {
      this.loadingService.hide();
      this.isLoading = false
    }
  }

  async loadTopHotelsPageData() {
    await this.apiService.getTopHotelsPage().then(async (response: any) => {
      this.sections = response.data;
      if (this.sections.banner[0].image) {
        this.bannerImageUrl = this.sections?.banner[0].image
      }

    });
  }


  async loadTopHotelsList() {
    await this.apiService.getTopHotelsList(this.currentPage).then(async (response: any) => {
      this.hotelsTotalPages = response.data.total_page;
      this.hotelsData = response.data.data;
    });

  }

  async loadMoreHotels() {

    if (this.isLoading) return;
    this.isLoading = true;
    this.loadingService.show();

    try {
      this.currentPage++;

      const response = await this.apiService.getTopHotelsList(this.currentPage);

      this.hotelsTotalPages = response.data.total_page;
      this.hotelsData = [...this.hotelsData, ...response.data.data];

      setTimeout(() => this.scrollToBottom(), 0);

    } catch (error) {
      console.error('Error loading more hotels:', error);
    } finally {
      this.loadingService.hide();
      this.isLoading = false;
    }
  }

  scrollToBottom() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  }

  getFullStars(rating: number): number[] {
    // Returns an array representing the full stars
    return Array(Math.floor(rating)).fill(0);
  }

  hasHalfStar(rating: number): boolean {
    // Checks if there is a half star
    return rating % 1 >= 0.5;
  }

  getEmptyStars(rating: number): number[] {
    // Returns an array representing the empty stars
    const fullStars = Math.floor(rating);
    const hasHalf = rating % 1 >= 0.5 ? 1 : 0;
    return Array(5 - fullStars - hasHalf).fill(0);
  }

}
