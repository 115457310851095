<div class="footer" *ngIf="!isLoading">
    <div class="container-fluid">
        <div class="footer-top">
            <a class="footer-logo" href='/'>
                <img [src]="logoSrc" alt="footer-logo">
            </a>
            <ul class="footer-navbar">
                <li>
                    <a href="/" (click)="navigateTo($event, '/')" [class.active]="isActive('/')">Home</a>
                </li>
                <li>
                    <a href="/about-us/" (click)="navigateTo($event, '/about-us/')"
                        [class.active]="isActive('/about-us/')">About Us</a>
                </li>
                <li>
                    <a [class.active]="isActive('/top-hotels/') || isActive('/top-hotels-')" href="/top-hotels/"
                        (click)="navigateTo($event, '/top-hotels/')">Top
                        Hotels</a>

                </li>
                <li>
                    <a [class.active]="isActive('/contact-us/')" href="/contact-us/"
                        (click)="navigateTo($event, '/contact-us/')">Contact
                        Us</a>
                </li>
                <li>
                    <a [class.active]="isActive('/disclaimer/')" href="/disclaimer/"
                        (click)="navigateTo($event, '/disclaimer/')">Disclaimer</a>
                </li>
                <!-- <li>
                    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a>
                </li>
                <li>
                    <a routerLink="/about-us" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">About Us</a>
                </li>
                <li>
                    <a routerLink="/top-hotels" [class.active]="isTopHotelsActive()">Top Hotels</a>
                </li>
                <li>
                    <a routerLink="/contact-us" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">Contact Us</a>
                </li>
                <li>
                    <a routerLink="/disclaimer" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">Disclaimer</a>
                </li> -->
            </ul>
        </div>
        <div class="footer-bottom">
            <ul>
                <li>
                    <a [class.active]="isActive('/privacy-policy/')" href="/privacy-policy/"
                        (click)="navigateTo($event, '/privacy-policy/')">Privacy Policy</a>
                </li>
                <li>
                    <a [class.active]="isActive('/cookies/')" href="/cookies/"
                        (click)="navigateTo($event, '/cookies/')">Cookies Policy</a>
                </li>
                <li>
                    <a [class.active]="isActive('/terms/')" href="/terms/" (click)="navigateTo($event, '/terms/')">Terms
                        & Conditions</a>
                </li>
            </ul>
            <p>© {{currentYear}} {{sections?.footer[0]?.title}}.</p>
        </div>
    </div>
</div>
<!-- Cookie Consent Modal -->
<div class="modal active" id="CookiesModal" *ngIf="showBanner === true">
    <div class="box">
        <h3 class="h3">WE USE COOKIES</h3>
        <div class="close close-modal" (click)="showBanner = false">✕</div>
        <p>We use our own and third-party cookies to personalize content and to analyze web traffic.</p>
        <button class="site-btn" (click)="acceptCookies()">Accept</button>
        <a href="/cookies/" target="_blank" class="link-site-btn">More information</a>
    </div>
</div>