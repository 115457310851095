import { Component } from '@angular/core';
import { TopHotelsComponent } from '../top-hotels/top-hotels.component';

@Component({
  selector: 'app-top-hotels-section',
  standalone: true,
  imports: [
    TopHotelsComponent
  ],
  templateUrl: './top-hotels-section.component.html',
  styleUrl: './top-hotels-section.component.css'
})
export class TopHotelsSectionComponent {

}
