<!-- Banner Section -->
<div class="banner">
    <div class="container-fluid" *ngIf="sections?.banner && sections?.banner.length > 0">
        <div class="banner-inner" [ngStyle]="{ 'background-image': 'url(' + backgroundImageUrl + ')' }">
            <div class="banner-content">
                <h1 class="h1">{{ sections?.banner[0]?.title }}</h1>
                <p>{{ sections?.banner[0]?.short_description }}</p>
                <a [href]="sections?.banner[0]?.button_link " class="site-btn" target="_blank">{{
                    sections?.banner[0]?.button_text}}</a>
            </div>
        </div>
    </div>
</div>

<!-- Top Hotels -->

<div class="top-hotels-sec">
    <div class="container-fluid">
        <div class="sec-heading">
            <h2 class="h2" *ngIf="sections?.top_hotels?.length > 0">
                <span class="primary-text">{{ sections?.top_hotels[0]?.title }}</span>
                {{sections?.top_hotels[0]?.short_description }}
            </h2>
            <a *ngIf="sections?.top_hotels?.length > 0" [href]="sections?.top_hotels[0]?.button_link" class="site-btn"
                target="_blank">Explore
                More</a>
        </div>
        <div class="top-hotels-slider row">
            <div class="col" *ngFor="let hotel of hotelsData;let i=index">
                <div class="top-hotel-items">
                    <img [src]="hotel?.images?.image" alt="hotel-img">
                    <div class="top-hotel-content">
                        <h3 class="h6">{{hotel?.name}}</h3>
                        <p class="top-hotel-discription" [innerHtml]="hotel?.description"></p>
                        <a [href]="hotel.hotelUrl" class="link-btn" target="_blank">Read More <img
                                src="assets/img/arrow.webp" alt="arrow"></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- Travel Packages -->
<div class="travel-packages-sec">
    <div class="container-fluid">
        <div class="sec-heading">
            <h2 class="h2" *ngIf="sections?.travel_packages?.length > 0">
                <span class="primary-text">{{ sections?.travel_packages[0]?.title }}</span>
                {{sections?.travel_packages[0]?.short_description }}
            </h2>
        </div>

        <div class="travel-packages-slider">
            <div *ngFor="let travelPackagesSet of travelPackagesSets" class="travel-packages-items">
                <div class="travel-packages-row">
                    <div *ngFor="let package of travelPackagesSet" class="travel-packages-col">
                        <img [src]="package['image']" alt="packages-img">
                        <div class="travel-packages-content">
                            <div class="tpc-top">
                                <div class="tpc-top-left">
                                    <h3 class="h6">{{ package['name'] }}</h3>
                                    <p>{{ package['stay'] }}</p>
                                </div>
                                <span class="day-tags">{{ package['no_of_nights'] }}N/{{ package['no_of_days']
                                    }}D</span>
                            </div>
                            <ul>
                                <li *ngFor="let activity of package['include_facilities']">{{ activity }}</li>
                            </ul>
                            <a href="#" (click)="personalizeModalOpen(package)">Personalize</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal active" id="personalizeModal" *ngIf="isPersonalizeModalOpen">
    <div class="box">
        <h3 class="h4">{{PersonalizeModalData?.name}}
        </h3>
        <div class="close close-modal" (click)="personalizeModalClose()">✕</div>
       <!-- validator-ignore -->
        <form class="content-form" [formGroup]="personalizeForm" (ngSubmit)="submitPersonalizeForm()">
            <div class="form-group">
                <input type="text" name="full_name" placeholder="Your Name" id="full_name" formControlName="full_name">
                <div *ngIf="personalizeForm.get('full_name')?.hasError('apiError')" class="invalid-error">
                    <span>{{ personalizeForm.get('full_name')?.getError('apiError') }}</span>
                </div>
            </div>
            <div class="form-group">
                <input type="text" name="phone_no" placeholder="Phone Number" id="phone_no" formControlName="phone_no">
                <div *ngIf="personalizeForm.get('phone_no')?.hasError('apiError')" class="invalid-error">
                    <span>{{ personalizeForm.get('phone_no')?.getError('apiError') }}</span>
                </div>

            </div>
            <div class="form-group">
                <input type="email" name="email" placeholder="Your E-mail" id="email" formControlName="email">
                <div *ngIf="personalizeForm.get('email')?.hasError('apiError')" class="invalid-error">
                    <span>{{ personalizeForm.get('email')?.getError('apiError') }}</span>
                </div>
            </div>
            <div class="form-group">
                <textarea placeholder="Your message" id="message" name="message" formControlName="message"></textarea>
                <div *ngIf="personalizeForm.get('message')?.hasError('apiError')" class="invalid-error">
                    <span>{{ personalizeForm.get('message')?.getError('apiError') }}</span>
                </div>

            </div>
            <div class="form-group">
                <input type="submit" class="site-btn" name="submit" id="contact-form-submit" value="Submit">
            </div>
            <div id="toast-container"></div>
        </form>
    </div>
</div>
<!-- Contact Section -->

<div class="contact-sec">
    <div class="container-fluid">
        <div class="contact-inner">
            <div class="row">
                <div class="col-xl-6 col-lg-12">
                    <app-contact-form></app-contact-form>
                </div>
                <div class="col-xl-6 col-lg-12 contact-col">
                    <div class="contact-img" *ngIf="sections?.contact_us?.length > 0">
                        <img [src]="sections?.contact_us[0]?.image" alt="contact-img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>