import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { HomeSectionService } from '../services/home-section.service';
import { conf } from '../../environments/env.conf';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  @ViewChild('cookiesModal') cookiesModal!: ElementRef;

  sections: any;
  logoSrc: string = 'assets/img/footer-logo.webp';
  logoUrl = conf.siteUrl;
  currentYear = new Date().getFullYear();
  isLoading = true;
  showBanner: boolean | null = null;
  currentPath: string = '';

  constructor(
    private homeSectionService: HomeSectionService,
    private router: Router,
    private apiService: ApiService,
    public loadingService: LoadingService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  isTopHotelsActive(): boolean {
    return this.apiService.isTopHotelsActive();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkConsent();

      window.addEventListener('storage', () => this.checkConsent());
    }

    this.loadingService.show();

    // Check url 
    this.currentPath = this.router.url;
    this.router.events.subscribe(() => {
      this.currentPath = this.router.url;
    });


    setTimeout(() => {
      this.homeSectionService.getHomeSectionData().subscribe(
        data => {
          this.sections = data;
          if (this.sections?.footer[0]?.image) {
            this.logoSrc = this.sections.footer[0].image;
          }
          if (this.sections?.footer[0]?.button_link) {
            this.logoUrl = this.sections.footer[0].button_link;
          }
          this.isLoading = false; // Data loaded, hide loading indicator
          this.loadingService.hide(); // Hide global loader
        },
        error => {
          this.isLoading = false; // Still hide loader on error
          this.loadingService.hide();
        }
      );
    }, 500);
  }

  isActive(path: string): boolean {
    return this.apiService.isActiveClass(path, this.currentPath);
  }

  navigateTo(event: Event, path: string): void {
    event.preventDefault();
    this.router.navigateByUrl(path);
  }


  checkConsent(): void {
    if (isPlatformBrowser(this.platformId)) {
      const cookieAccepted = localStorage.getItem('cookie-consent');
      this.showBanner = cookieAccepted !== 'true';
    }
  }

  acceptCookies(): void {
    localStorage.setItem('cookie-consent', 'true');
    this.showBanner = false;
  }


}
