export const META_INFO: { [key: string]: { title: string; description: string } } = {
  "/": {
    title: "Home | Compare Hotel Options",
    description: "Home | Welcome To The Compare Hotel Options",
  },
  "/home/": {
    title: "Home | Compare Hotel Options",
    description: "Welcome To The Compare Hotel Options"
  },
  "/about-us/": {
    title: "About Us | Compare Hotel Options",
    description: "About Us | Compare Hotel Options"
  },
  "/contact-us/": {
    title: "Contact Us | Compare Hotel Options",
    description: "Contact Us | Compare Hotel Options"
  },
  "/top-hotels/": {
    title: "Top Hotels | Compare Hotel Options",
    description: "Top Hotels | Compare Hotel Options"
  },
  "/disclaimer/": {
    title: "Disclaimer | Compare Hotel Options",
    description: "Disclaimer | Compare Hotel Options"
  },
  "/privacy-policy/": {
    title: "Privacy Policy | Compare Hotel Options",
    description: "Privacy Policy | Compare Hotel Options"
  },
  "/cookies/": {
    title: "Cookies | Compare Hotel Options",
    description: "Cookies | Compare Hotel Options"
  },
  "/terms/": {
    title: "Terms and Conditions | Compare Hotel Options",
    description: "Terms and Conditions | Compare Hotel Options"
  }
};
