<div *ngIf="!isLoading">
    <!-- Banner Section -->
    <div class="inner-page-banner">
        <div class="container-fluid">
            <div class="inner-page-banner-bg" [ngStyle]="{ 'background-image': 'url(' + bannerImageUrl + ')' }">
                <h1 class="h1" *ngIf="sections?.banner">{{sections?.banner[0].title}}</h1>
            </div>
        </div>
    </div>

    <!-- Hotel List Section -->
    <div class="top-hotel-list-sec">
        <div class="container">
            <div class="top-hotel-list-items" *ngFor="let hotel of hotelsData; let i = index">
                <div class="row">
                    <div class="col-xl-5 tp-list-img-col">
                        <div class="tp-list-img">
                            <img [src]="hotel.image" alt="{{hotel.name}} image">
                        </div>
                    </div>
                    <div class="col-xl-7 tp-list-content-col">
                        <div class="tp-list-content">
                            <h3 class="h4">{{hotel?.name}}</h3>
                            <div class="reviews">
                                <div class="reviews-star" *ngIf="hotel?.rating">
                                    <!-- Full stars based on hotel rating -->
                                    <img *ngFor="let star of getFullStars(hotel.rating)" src="assets/img/feel-star.webp" alt="full-star">
                                    <!-- Half star if applicable -->
                                    <img *ngIf="hasHalfStar(hotel.rating)" src="assets/img/half-feel-star.webp" alt="half-star">
                                    <!-- Empty stars based on hotel rating -->
                                    <img *ngFor="let star of getEmptyStars(hotel.rating)" src="assets/img/without-feel-star.webp" alt="empty-star">
                                </div>
                                <span>({{hotel?.reviews}} reviews)</span>
                            </div>
                            <span class="primary-text" *ngIf="hotel?.facilities?.length">This property offers:</span>
                            <ul class="listing-tags">
                                <li *ngFor="let fac of hotel.facilities; let i = index">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" *ngIf="fac.image">
                                        <image [attr.href]="fac.image" width="24" height="23"></image>
                                    </svg>
                                    {{fac.name}}
                                </li>
                            </ul>
                            <div class="top-hotel-list-discription" [innerHtml]="hotel?.description"></div>
                            <a [href]="hotel.hotelUrl" class="site-btn" target="_blank">Check Now</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Load More Button -->
            <a href="javascript:void(0);" (click)="loadMoreHotels()" class="site-btn load-more-btn" *ngIf="hotelsTotalPages > currentPage">Load More</a>
        </div>
    </div>
</div>
<app-footer></app-footer>