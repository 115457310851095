<div class="contact-form" *ngIf="!isLoading">
    <h2 class="h2" *ngIf="sections?.top?.length > 0">{{sections?.top[0]?.title}}</h2>
    <p *ngIf="sections?.top?.length > 0">{{sections?.top[0]?.description}}</p>

    <!-- W3C Validator Ignore -->
    <form class="content-form" [formGroup]="contactForm" (ngSubmit)="submitForm()">
        <div class="form-group">
            <input type="text" name="full_name" placeholder="Your Name" id="full_name" [formControlName]="'full_name'">
            <div *ngIf="contactForm.get('full_name')?.hasError('apiError')" class="invalid-error">
                <span>{{ contactForm.get('full_name')?.getError('apiError') }}</span>
            </div>
        </div>
        <div class="form-group">
            <input type="text" name="phone_no" placeholder="Phone Number" id="phone_no" [formControlName]="'phone_no'">
            <div *ngIf="contactForm.get('phone_no')?.hasError('apiError')" class="invalid-error">
                <span>{{ contactForm.get('phone_no')?.getError('apiError') }}</span>
            </div>

        </div>
        <div class="form-group">
            <input type="email" name="email" placeholder="Your E-mail" id="email" [formControlName]="'email'">
            <div *ngIf="contactForm.get('email')?.hasError('apiError')" class="invalid-error">
                <span>{{ contactForm.get('email')?.getError('apiError') }}</span>
            </div>
        </div>
        <div class="form-group">
            <textarea placeholder="Your message" id="message" name="message" [formControlName]="'message'"></textarea>
            <div *ngIf="contactForm.get('message')?.hasError('apiError')" class="invalid-error">
                <span>{{ contactForm.get('message')?.getError('apiError') }}</span>
            </div>

        </div>
        <div class="form-group">
            <input type="submit" class="site-btn" name="submit" id="contact-form-submit" value="Submit">
        </div>
        <!-- End Ignore -->
        <div id="toast-container"></div>
    </form>

</div>