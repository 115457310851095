import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HomeSectionService } from '../services/home-section.service';
import { filter } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { conf } from '../../environments/env.conf';
import $ from 'jquery';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements AfterViewInit {
  homeSectionData: any;
  logoSrc: string = ''; // Set default logo path
  isNavbarOpen = false;
  isTogglingNavbar = false; // Flag to prevent simultaneous toggling
  collapseListener: any;
  isLoading = true;
  currentPath: string = '';
  logoUrl = conf.siteUrl;


  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private homeSectionService: HomeSectionService,
    private router: Router,
    public loadingService: LoadingService,
    private apiService: ApiService) { }

  isTopHotelsActive(): boolean {
    return this.apiService.isTopHotelsActive();
  }

  ngAfterViewInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.isNavbarOpen && !this.isTogglingNavbar) {
        this.toggleNavbar();
      }
    });
  }

  toggleNavbar() {
    if (this.isTogglingNavbar) return;

    this.isTogglingNavbar = true;
    this.isNavbarOpen = !this.isNavbarOpen;
    document.body.classList.toggle('open-menu', this.isNavbarOpen);

    const navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse) {
      setTimeout(() => {
        navbarCollapse.classList.toggle('collapse', !this.isNavbarOpen);
        this.isTogglingNavbar = false;
      }, 300);
    }
  }

  async ngOnInit() {
    this.loadingService.show();

    this.currentPath = this.router.url;
    // Listen for route changes to update the active link
    this.router.events.subscribe(() => {
      this.currentPath = this.router.url;
    });



    await this.loadHomePageData()
    if (this.isNavbarOpen && !this.isTogglingNavbar) {
      this.toggleNavbar();
    }
  }

  isActive(path: string): boolean {
    return this.apiService.isActiveClass(path, this.currentPath);
  }

  navigateTo(event: Event, path: string): void {
    event.preventDefault();
    if (this.currentPath === path) {
      $('body').removeClass('open-menu');
      $('.navbar-collapse').removeClass('collapse');
      $('.navbar-toggler').addClass('collapsed');
    }

    this.router.navigateByUrl(path);
  }



  async loadHomePageData() {
    await this.apiService.getHomePage().then(async (response: any) => {
      this.homeSectionData = response.data;
      if (this.homeSectionData?.logo?.length && this.homeSectionData?.logo[0]?.image) {
        this.logoSrc = this.homeSectionData.logo[0].image;
      }
    });
    this.homeSectionService.updateHomeSectionData(this.homeSectionData);
    this.isLoading = false;
    this.loadingService.hide();
  }



  public loadCustomScript() {
    const node = document.createElement('script');
    node.src = 'assets/js/custom.js'; // put there your js file location
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  public loadSlickScript() {
    const node = document.createElement('script');
    node.src = 'assets/js/slick.min.js'; // put there your js file location
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
