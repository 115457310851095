import { conf } from "./env.conf";

export const config = {
    api: {
        homePage: `${conf.apiHost}home-page?page=home`,
        contactPage: `${conf.apiHost}home-page?page=contact_us`,
        getAboutUsPage: `${conf.apiHost}home-page?page=about_us`,
        getDisclaimerPage: `${conf.apiHost}home-page?page=disclaimer`,
        getPrivacyPolicyPage: `${conf.apiHost}home-page?page=privacy-policy`,
        getCookiesPage: `${conf.apiHost}home-page?page=cookies-policy`,
        getTermsPage: `${conf.apiHost}home-page?page=terms-conditions`,
        getTopHotelsPage: `${conf.apiHost}home-page?page=top_hotels`,
        topHotels: `${conf.apiHost}top-hotels`,
        listTopHotels: `${conf.apiHost}list-top-hotels`,
        travelPackages: `${conf.apiHost}travel-packages`,
        submitContactUs: `${conf.apiHost}contact-us`,
        submitTravelPackage: `${conf.apiHost}travel-package/personalize`,
        getHotelDetails: (hotelId: any) => `${conf.apiHost}hotel-details/${hotelId}`,
    }
}
