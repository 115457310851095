import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
import { config } from '../../environments/config';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private router: Router) { }

  isTopHotelsActive(): boolean {
    return this.router.url === '/top-hotels' || this.router.url.startsWith('/hotel');
  }

  isActiveClass(path: string, currentPath: string): boolean {
    const normalizedPath = currentPath.endsWith('/') ? currentPath : currentPath + '/';
    if (path === '/') {
      return normalizedPath === '/';
    } else if (path === '/top-hotels/') {
      return normalizedPath === path || normalizedPath.startsWith('/hotel-') || normalizedPath.startsWith('/top-hotels/');
    } else {
      return normalizedPath === path;
    }
  }

  setApiErrors(form: FormGroup, errors: any[]) {
    errors.forEach((errorObj) => {
      Object.keys(errorObj).forEach((field) => {
        const control = form.get(field);
        if (control) {
          control.setErrors({ apiError: errorObj[field] });
        }
      });
    });
  }

  handleErrors(response: any) {
    if (!response.ok && response.status != 200) {
      // this.router.navigate(['']);
    }
    return response.json();
  }

  getHomePage(): Promise<any> {
    return fetch(config.api.homePage).then(this.handleErrors);

  }

  getTopHotelsData(): Promise<any> {
    return fetch(config.api.topHotels).then(this.handleErrors);

  }
  getTravelPackages(): Promise<any> {
    return fetch(config.api.travelPackages).then(this.handleErrors);

  }
  getContactUsPage(): Promise<any> {
    return fetch(config.api.contactPage).then(this.handleErrors);

  }
  getAboutUsPage(): Promise<any> {
    return fetch(config.api.getAboutUsPage).then(this.handleErrors);
  }

  getDisclaimerPage(): Promise<any> {
    return fetch(config.api.getDisclaimerPage).then(this.handleErrors);
  }

  getPrivacyPolicyPage(): Promise<any> {
    return fetch(config.api.getPrivacyPolicyPage).then(this.handleErrors);
  }
  getCookiesPage(): Promise<any> {
    return fetch(config.api.getCookiesPage).then(this.handleErrors);
  }
  getTermsPage(): Promise<any> {
    return fetch(config.api.getTermsPage).then(this.handleErrors);
  }

  getTopHotelsPage(): Promise<any> {
    return fetch(config.api.getTopHotelsPage).then(this.handleErrors);
  }

  async submitContactUsForm(formData: any): Promise<any> {
    try {
      const response = await fetch(config.api.submitContactUs, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      return response.json().then(data => ({
        status: response.status,
        body: data
      }));

    } catch (error) {
      await this.router.navigate(['/']);
    }
  }

  async submitPersonalizeForm(formData: any): Promise<any> {

    try {
      const response = await fetch(config.api.submitTravelPackage, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      return response.json().then(data => ({
        status: response.status,
        body: data
      }));
    } catch (error) {
      await this.router.navigate(['/']);
    }
  }

  getTopHotelsList(page: Number): Promise<any> {
    const url = `${config.api.listTopHotels}?page=${page}`;
    return fetch(url).then(this.handleErrors);
  }

  async getHotelDetails(hotelId: any): Promise<any> {
    try {
      const response = await fetch(config.api.getHotelDetails(hotelId));
      const data = await response.json();
      return data;
    } catch (error) {
      setTimeout(async () => {
        await this.router.navigate(['/404']);
      }, 500);
    }
  }
}
