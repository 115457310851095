// Local environment
// export const conf = {
//     apiHost: 'http://localhost/angular-backend/api/',
//     production: false,
//     siteUrl: 'http://localhost:4200/'
// };

// Production environment
// export const conf = {
//     apiHost: 'https://comparehoteloptions.com/angular-backend/',
//     production: true,
//     siteUrl: 'https://comparehoteloptions.com/'
// };

// development environment
// export const conf = {
//     apiHost: 'http://15.207.152.121/angular-backend/api/',
//     production: true,
//     siteUrl: 'http://15.207.152.121/angular-web/'
// };



// // Production environment
export const conf = {
    apiHost: 'https://comparehoteloptions.com/api/web/',
    production: true,
    siteUrl: 'https://comparehoteloptions.com/'
};