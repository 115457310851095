import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { META_INFO } from '../meta-config';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private titleService: Title,
    private metaService: Meta

  ) {

  }
  updateMeta(url: string) {

    const metaInfo = META_INFO[url] || META_INFO['/'];
    if (url.startsWith('/top-hotels/')) {
      this.titleService.setTitle('Top Hotels | Compare Hotel Options')
      this.metaService.updateTag({ name: 'description', content: 'Top Hotels | Compare Hotel Options' });
    } else {
      // Update title and description
      this.titleService.setTitle(metaInfo.title);
      this.metaService.updateTag({ name: 'description', content: metaInfo.description });
    }
    this.metaService.updateTag({ name: 'robots', content: "follow,index" })
  }
}
